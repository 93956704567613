// 告警类型
export const alarmTypes = [
  {
    dictKey: "",
    dictValue: "全部",
  },
  {
    dictKey: 1,
    dictValue: "紧急报警",
  },
  {
    dictKey: 2,
    dictValue: "异常工况",
  },
  {
    dictKey: 3,
    dictValue: "天气预警",
  },
];
//干预方式
export const interventionTypes = [
  //   {
  //     dictKey: "",
  //     dictValue: "全部",
  //   },
  {
    dictKey: 1,
    dictValue: "致电联系人",
  },
  {
    dictKey: 2,
    dictValue: "短息通知",
  },
  {
    dictKey: 3,
    dictValue: "远程对讲",
  },
  {
    dictKey: 4,
    dictValue: "TTS语音下发",
  },
];

//tts语音下发
export const ttsVoices = [
  {
    dictKey: 1,
    dictValue: "请注意盲区报警，注意安全",
  },
  {
    dictKey: 2,
    dictValue: "请注意碰撞报警，注意安全",
  },
  {
    dictKey: 3,
    dictValue: "违规操作，请立即停止",
  },
  {
    dictKey: 4,
    dictValue: "疲劳作业，注意休息",
  },
];
