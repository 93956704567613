// 安全指数等级：
// 风险评分0-40(小于40)：差 颜色：#F64A2D
// 风险评分40(大于等于40)-60（小于60）：较差 颜色：#FD6C35
// 风险评分60(大于等于60)-80（小于80）：一般 颜色：#FF9B14
// 风险评分80(大于等于80)-90（小于90）：良好 颜色：#0054FF
// 风险评分90(大于等于90)-100（小于等于100）：优秀 颜色：#00C86B
export function getRiskLevel(riskScore = "") {
  let rc = riskScore;
  if (rc === "") {
    return {
      color: "#00C86B",
      color2: "#FF9381",
      shadow: "0px 0px 10px 1px rgba(48,255,55,0.26)",
      text: "---",
    };
  }
  if (rc < 40) {
    return {
      color: "#F64A2D",
      color2: "#FF9381",
      shadow: "0px 0px 10px 1px rgba(255,48,48,0.26)",
      text: "差",
    };
  } else if (rc >= 40 && rc < 60) {
    return {
      color: "#FD6C35",
      color2: "#FFA582",
      shadow: "0px 0px 10px 1px rgba(255,165,48,0.26)",
      text: "较差",
    };
  } else if (rc >= 60 && rc < 80) {
    return {
      color: "#FF9B14",
      color2: "#FFC575",
      shadow: "0px 0px 10px 1px rgba(255,165,48,0.26)",
      text: "一般",
    };
  } else if (rc >= 80 && rc < 90) {
    return {
      color: "#0054FF",
      color2: "#38A4FF",
      shadow: "0px 0px 10px 1px rgba(54,160,252,0.31)",
      text: "良好",
    };
  } else if (rc >= 90) {
    return {
      color: "#00C86B",
      color2: "#76E9B4",
      shadow: "0px 0px 10px 1px rgba(48,255,55,0.26)",
      text: "优秀",
    };
  } else {
    return {
      color: "#00C86B",
      color2: "#FF9381",
      shadow: "0px 0px 10px 1px rgba(48,255,55,0.26)",
      text: "---",
    };
  }
}
//根据身份获取对应不显示配置
export function getIdentityNotShowConfig(identity = "") {
  // 1 客户 2 保司 3 政府 4 协会
  if (identity == 3 || identity == 4) {
    return {
      ProjectIndicat: {
        items: [
          {
            key: "signAmount",
            show: false,
          },
          {
            key: "targetAmount",
            show: false,
          },
          {
            key: "orderAmount",
            show: false,
          },
          {
            key: "profitAmount",
            show: false,
          },
          {
            key: "orderRate",
            show: false,
          },
          {
            key: "signCtrlRate",
            show: false,
          },
          {
            key: "targetCtrlRate",
            show: false,
          },
        ],
      },
      PolicyOnline: {
        items: [
          {
            key: "renewalCount",
            show: false,
          },
          {
            key: "averagePremium",
            show: false,
          },
        ],
      },
      ServiceOnline: {
        items: [
          {
            key: "amountPayable",
            show: false,
          },
          {
            key: "notRiskAmount",
            show: false,
          },
          {
            key: "zeroCompensateCount",
            show: false,
          },
          {
            key: "zeroRiskAmount",
            show: false,
          },
        ],
      },
      DriverOnline: {
        items: [
          {
            key: "jsCXCount",
            show: false,
          },
        ],
      },
    };
  } else if (identity == 2) {
    return {
      ProjectIndicat: {
        items: [
          {
            key: "targetAmount",
            show: false,
          },
          {
            key: "targetRate",
            show: false,
          },
          {
            key: "profitAmount",
            show: false,
          },
          {
            key: "orderRate",
            show: false,
          },
          {
            key: "targetCtrlRate",
            show: false,
          },
          {
            key: "signCtrlRate",
            show: false,
          },
        ],
      },
      PolicyOnline: {
        items: [
          {
            key: "averagePremium",
            show: false,
          },
        ],
      },
      DriverOnline: {
        items: [
          {
            key: "jsCXCount",
            show: false,
          },
        ],
      },
    };
  } else if (identity == 1) {
    return {
      ProjectIndicat: {
        items: [
          {
            key: "signAmount",
            show: false,
          },
          {
            key: "signRate",
            show: false,
          },
          {
            key: "targetAmount",
            show: false,
          },
          {
            key: "targetRate",
            show: false,
          },
          {
            key: "profitAmount",
            show: false,
          },
          {
            key: "signCtrlRate",
            show: false,
          },
          {
            key: "targetCtrlRate",
            show: false,
          },
        ],
      },
      PolicyOnline: {
        items: [
          {
            key: "averagePremium",
            show: false,
          },
        ],
      },
      DriverOnline: {
        items: [
          {
            key: "jsCXCount",
            show: false,
          },
        ],
      },
      ServiceOnline: {
        items: [
          {
            key: "notRiskAmount",
            show: false,
          },
          {
            key: "zeroCompensateCount",
            show: false,
          },
          {
            key: "zeroRiskAmount",
            show: false,
          },
        ],
      },
    };
  } else {
    return {
      DriverOnline: {
        items: [
          {
            key: "jsCXCount",
            show: false,
          },
        ],
      },
    };
  }
}
const alarmTypeNameStyles = [
  {
    name: "紧急报警",
    style: {
      color: "#F64A2D",
      background: "rgba(246,74,45,0.1)",
      border: "1px solid rgba(246,74,45,0.3)",
      padding: "2px 8px",
    },
  },
  {
    name: "天气警报",
    style: {
      color: "#FDA835",
      background: "rgba(253,168,53,0.1)",
      border: "1px solid rgba(253,168,53,0.3);",
      padding: "2px 8px",
    },
  },
  {
    name: "异常工况",
    style: {
      color: "#FF924A",
      background: "rgba(255,146,74,0.1)",
      border: "1px solid rgba(255,146,74,0.3);",
      padding: "2px 8px",
    },
  },
];
//根据报警类型获取样式
export function getAlarmTypeStyle(alarmType) {
  let obj = alarmTypeNameStyles.find((item) => {
    return item.name == alarmType;
  });
  if (obj) {
    return obj.style;
  }
  return {
    color: "#F64A2D",
    background: "rgba(246,74,45,0.1)",
    border: "1px solid rgba(246,74,45,0.3)",
    padding: "2px 8px",
  };
}
